<template>
    <div style="max-width: 1800px" class="PanelProceeds mx-auto">
        <v-card class="ma-3 elevation-6">
            <v-toolbar class="mb-2" height="50" color="indigo darken-6 elevation-3" dark flat>
                <v-toolbar-title class="overline">Выручка </v-toolbar-title>
            </v-toolbar>
            <v-row class="pa-5 pb-0" v-show="show_select_points">
                <v-col cols="12">
                    <v-autocomplete :items="filter_points" @change="setPoint" v-model="point" item-text="name" item-value="id" class="ma-0 pa-0" persistent-hint return-object single-line solo></v-autocomplete>
                </v-col>
            </v-row>
            <v-row class="pl-5 pr-5">
                <v-col cols="12">
                    <Bar :chartData="chartData" :options="options" />
                    <v-progress-linear v-show="bar_loading_show" indeterminate color="indigo"></v-progress-linear>
                </v-col>
            </v-row>
            <v-row class="pl-5 pr-5">
                <v-col cols="12" xl="5">
                    <v-row>
                        <v-col cols="8">
                            <p class="pb-1">По времени:</p>
                            <v-select :items="time_interval" v-model="time_set" label="Время" solo @change="setPreset"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <p class="pb-1">Охват:</p>
                            <v-select :items="deep_list" @change="get_Chart" v-model="deep_selected" item-text="name" item-value="deep" item-disabled="customDisabled" class="ma-0 pa-0" persistent-hint return-object single-line solo></v-select>
                        </v-col>
                    </v-row>

                    <div v-show="show_calendar" class="pb-5">
                        <template>
                            <v-date-picker range v-model="dates_calendar" locale="ru-ru" selected-items-text="Выбран период" :first-day-of-week="1" :elevation="6" full-width @change="setPreset()"> </v-date-picker>
                        </template>
                    </div>
                    <p class="pb-1">Конкурентный анализ по заведениям:</p>
                    <v-select v-model="filter" :items="items" chips label="Фильтры" multiple solo @change="setFilter"></v-select>
                    <div v-show="show_slider">
                        <v-row>
                            <v-col cols="3">
                                <p>Радиус: {{ slider }} км</p>
                            </v-col>
                            <v-col cols="9"><v-slider v-model="slider" :max="20" :min="0.5" step="0.5" ticks thumb-label @change="setFilter"></v-slider></v-col
                        ></v-row>
                    </div>
                </v-col>
                <v-col cols="12" xl="7">
                    <div v-show="show_results">
                        <div class="grey lighten-5 pa-5 ma-2">
                            <v-row class="subheading text-overline text-center justify-center"><p class="ma-0 pa-2">Итоги за выбранный период</p></v-row>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-card class="pa-2">
                                        <Pie :chartData="PiechartData" :options="pie_options" />
                                    </v-card>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-data-table :headers="headers_results_table" :items="results_table" hide-default-footer class="elevation-1"></v-data-table>
                                </v-col>
                            </v-row>
                        </div>
                    </div>

                    <v-layout v-show="!show_results" class="grey lighten-5 subheading text-center justify-center" align-center fill-height column>
                        <p class="ma-0 pa-0">Нет заказов за выбранный период</p>
                        <p class="ma-0 pa-0">
                            C <b>{{ getHumanStrDate(time_start) }}</b> по <b>{{ getHumanStrDate(time_end) }}</b>
                            <!-- ${d.date} ${d.month_of_year}, ${d.day_of_week} -->
                        </p>
                        <p class="ma-0 pa-0">
                            Количество дней <b>{{ count_day }}</b>
                        </p>
                    </v-layout>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
    import Bar from './Bar.js'
    import Pie from './Pie.js'
    import DateTime from '../../mixins/DateTime.js'

    export default {
        mixins: [DateTime],
        data() {
            return {
                role: false,
                point_id: 0,
                name_chart_all: 'Выручка у других',
                name_chart_point: 'Выручка',
                point: { name: '', id: 0 },
                filter_points: [],
                deep_selected: { name: 'дневная', deep: 'days' },
                deep_list: [
                    { name: 'Месячный', deep: 'months', customDisabled: false }, // 0
                    { name: 'Дневной', deep: 'days', customDisabled: false }, // 1
                    { name: 'Часовой', deep: 'hours', customDisabled: false }, // 2
                ],

                time_start: null,
                time_end: null,
                count_day: 0,
                city: true,
                kitchen: null,
                radius: null,
                slider: 0,
                time_now: new Date(Date.now()),
                time_now_str: null,
                results_table: [],
                headers_results_table: [
                    { text: 'Параметр', value: 'text' },
                    { text: 'Значение', value: 'value' },
                ],

                items: ['Та же кухня', 'Общая выручка конкурентов, расположенных в радиусе от меня', 'В моем городе'],
                filter: ['В моем городе'],

                time_interval: ['Произвольный период', 'Сегодня', 'Вчера', 'Позавчера', '7 дней', '30 дней', '90 дней', '365 дней'],
                time_set: 'Сегодня',

                show_slider: false,
                show_calendar: false,
                show_select_points: false,
                show_results: false,
                bar_loading_show: false,

                dates_calendar: [],

                pie_options: {
                    cutoutPercentage: 60,
                    segmentShowStroke: true,
                    segmentStrokeColor: '#fff',
                    segmentStrokeWidth: 2,
                    // percentageInnerCutout: 50,
                    animationSteps: 100,
                    animationEasing: 'easeOutBounce',
                    animateRotate: true,
                    animateScale: false,
                    responsive: true,
                    maintainAspectRatio: true,
                    showScale: true,
                    legend: {
                        display: false,
                        //display: true,
                        position: 'bottom',
                        //fullWidth: true,
                        labels: {
                            fontSize: 10,
                            boxWidth: 15,
                            padding: 5,
                        },
                    },
                    tooltips: {
                        callbacks: {
                            title: function (tooltipItem, data) {
                                if (tooltipItem[0].index == 1) {
                                    return 'Выручка у других'
                                } else {
                                    return 'Выручка'
                                }
                            },

                            label: function (tooltipItem, data) {
                                let multistringText = []

                                multistringText.push(`Выручка: ${new Intl.NumberFormat('ru-RU').format(Number(data.datasets[0].data[tooltipItem.index]))} ₽`)
                                multistringText.push(`Заказы:  ${data.datasets[0].count_orders[tooltipItem.index]}`)

                                return multistringText
                            },
                        },
                        backgroundColor: '#FFF',
                        titleFontSize: 16,
                        titleFontColor: '#3f51b5',
                        bodyFontColor: '#666666',
                        //bodyFontSize: 14,
                        displayColors: false,
                        borderColor: '#3f51b5',
                        borderWidth: 2,
                        bodySpacing: 5,
                    },
                },

                options: {
                    tooltips: {
                        callbacks: {
                            title: function (tooltipItem, data) {
                                let multistringText = []
                                let time = data.datasets[0].date_object[tooltipItem[0].index]
                                let beautiful_hour_labels = hour => {
                                    let hour_str = ('0' + `${hour}`).slice(-2)
                                    return hour_str + ':00'
                                }

                                if ('hour' in time) {
                                    multistringText.push(`Дата: ${time.date} ${time.month_of_year} ${time.year}, ${time.day_of_week}`)
                                    multistringText.push(`Время: ${beautiful_hour_labels(time.hour)}`)
                                } else if ('date' in time) {
                                    multistringText.push(`Дата: ${time.date} ${time.month_of_year} ${time.year}, ${time.day_of_week}`)
                                } else {
                                    multistringText.push(`Дата: ${time.month_of_year} ${time.year}`)
                                }

                                return multistringText
                            },

                            label: function (tooltipItem, data) {
                                let multistringText = []

                                let order_count_point = data.datasets[0].number_orders[tooltipItem.index]
                                let order_count_all = data.datasets[1].number_orders[tooltipItem.index]

                                let proceeds_point = data.datasets[0].data[tooltipItem.index]
                                let proceeds_all = data.datasets[1].data[tooltipItem.index]
                                let sum = proceeds_all + proceeds_point

                                let percent_point = Math.round((100 * proceeds_point) / sum)
                                let percent_all = Math.round((100 * proceeds_all) / sum)

                                if (!percent_point) percent_point = 0
                                if (!percent_all) percent_all = 0

                                multistringText.push(` `)

                                multistringText.push(`🟩 Выручка: ${new Intl.NumberFormat('ru-RU').format(Number(proceeds_point))} ₽`)
                                multistringText.push(`Заказы:  ${order_count_point}`)
                                multistringText.push(`Доля: ${percent_point}%`)

                                multistringText.push(` `)

                                multistringText.push(`🟥 Выручка у других: ${new Intl.NumberFormat('ru-RU').format(Number(proceeds_all))} ₽`)
                                multistringText.push(`Заказы: ${order_count_all}`)
                                multistringText.push(`Доля: ${percent_all}%`)

                                return multistringText
                            },
                        },
                        backgroundColor: '#FFF',
                        titleFontSize: 16,
                        titleFontColor: '#3f51b5',
                        bodyFontColor: '#666666',
                        // bodyFontSize: 14,
                        displayColors: false,
                        borderColor: '#3f51b5',
                        borderWidth: 2,
                        bodySpacing: 5,
                    },
                    plugins: {
                        labels: false,
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [
                            {
                                stacked: true,
                                categoryPercentage: 0.5,
                                barPercentage: 1,
                            },
                        ],
                        yAxes: [
                            {
                                stacked: true,
                                ticks: {
                                    callback: function (label, index, labels) {
                                        return new Intl.NumberFormat('ru-RU').format(Number(label))
                                    },
                                },
                            },
                        ],
                    },
                },
                chartData: {},
                PiechartData: {},
            }
        },

        components: {
            Bar,
            Pie,
        },

        mounted() {
            this.setSetting()
        },

        methods: {
            setSetting() {
                this.time_now = new Date(this.time_now.setHours(this.time_now.getHours() - 3))
                this.time_now_str = this.getStrDate(this.time_now)
                this.time_start = this.time_now_str
                this.time_end = this.time_now_str
                this.role = this.$store.state.user.role
                switch (this.role) {
                    case 1: //operator
                        this.show_select_points = true
                        this.getPointsNameID()
                        break
                    case 3: //point
                        this.point_id = this.$store.state.user.point_id
                        this.show_select_points = false
                        this.setPreset()
                        break
                    default:
                        this.point_id = 0
                        break
                }
            },
            setRestrictionsDeep() {
                var Date1 = new Date(this.time_start)
                var Date2 = new Date(this.time_end)
                this.count_day = Math.floor((Date2.getTime() - Date1.getTime()) / (1000 * 60 * 60 * 24)) + 1

                if (this.count_day > 31) {
                    if (this.deep_selected.deep == 'hours') {
                        this.deep_selected = this.deep_list[1]
                    }

                    this.deep_list[2].customDisabled = true
                } else {
                    this.deep_list[2].customDisabled = false
                }
            },
            setPreset() {
                switch (this.time_set) {
                    case 'Сегодня':
                        this.time_start = this.time_now_str
                        this.time_end = this.time_now_str
                        this.show_calendar = false
                        this.deep_selected = this.deep_list[2]
                        break
                    case 'Вчера':
                        this.time_start = this.getStrDate(new Date().setDate(this.time_now.getDate() - 1))
                        this.time_end = this.time_start
                        this.show_calendar = false
                        this.deep_selected = this.deep_list[2]
                        break
                    case 'Позавчера':
                        this.time_start = this.getStrDate(new Date().setDate(this.time_now.getDate() - 2))
                        this.time_end = this.time_start
                        this.show_calendar = false
                        this.deep_selected = this.deep_list[2]
                        break
                    case '7 дней':
                        this.time_end = this.time_now_str
                        this.time_start = this.getStrDate(new Date().setDate(this.time_now.getDate() - 6))
                        this.show_calendar = false
                        this.deep_selected = this.deep_list[1]
                        break
                    case '30 дней':
                        this.time_end = this.time_now_str
                        this.time_start = this.getStrDate(new Date().setDate(this.time_now.getDate() - 29))
                        this.show_calendar = false
                        this.deep_selected = this.deep_list[1]
                        break
                    case '90 дней':
                        this.time_end = this.time_now_str
                        this.time_start = this.getStrDate(new Date().setMonth(this.time_now.getMonth() + 1 - 3))
                        this.show_calendar = false
                        this.deep_selected = this.deep_list[0]
                        break
                    case '365 дней':
                        this.time_end = this.time_now_str
                        this.time_start = this.getStrDate(new Date().setMonth(this.time_now.getMonth() + 1 - 12))
                        this.show_calendar = false
                        this.deep_selected = this.deep_list[0]
                        break
                    case 'Произвольный период':
                        this.show_calendar = true

                        this.dates_calendar.sort()
                        if (this.dates_calendar.length == 2) {
                            this.time_end = this.dates_calendar[1]
                            this.time_start = this.dates_calendar[0]
                        }
                        if (this.time_end == this.time_start && this.dates_calendar.length == 2) {
                            this.deep_selected = this.deep_list[2]
                        } else {
                            this.deep_selected = this.deep_list[1]
                        }

                        break
                }
                this.setRestrictionsDeep()
                this.get_Chart()
            },

            setPoint() {
                if (this.role == 1 && this.filter_points.length != 0) {
                    this.point_id = this.point.id
                }
                this.get_Chart()
            },

            setFilter() {
                if (this.filter.includes('Общая выручка конкурентов, расположенных в радиусе от меня')) {
                    this.show_slider = true
                    this.radius = this.slider
                } else {
                    this.show_slider = false
                    this.radius = null
                }

                if (this.filter.includes('В моем городе')) {
                    this.city = true
                } else {
                    this.city = null
                }

                if (this.filter.includes('Та же кухня')) {
                    this.kitchen = true
                } else {
                    this.kitchen = null
                }
                this.get_Chart()
            },

            getStrDate(date) {
                date = new Date(date)
                return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
            },

            getHumanStrDate(date) {
                date = new Date(date)
                // console.log('date', date)
                // console.log('toLocaleDateString', date.toLocaleDateString())
                // return `${ date.getDate() } ${ date.getMonth() } ${ date.getFullYear() }`;
                return date.toLocaleDateString()
            },

            getPointsNameID() {
                this.$store.dispatch('points_get').then(res => {
                    this.filter_points = res.data.points.map(p => ({
                        id: p.id,
                        name: p.name,
                    }))
                    if (this.filter_points.length != 0) {
                        this.point_id = this.filter_points[0].id
                        this.point = this.filter_points[0]
                        this.setPreset()
                    }
                })
            },

            get_Chart() {
                this.bar_loading_show = true
                if (this.point_id != 0) {
                    this.$store
                        .dispatch('get_proceeds_analytic', {
                            point_id: this.point_id, //
                            time_start: this.time_start,
                            time_end: this.time_end,
                            radius: this.radius * 1000,
                            city: this.city,
                            kitchen: this.kitchen,
                            deep: this.deep_selected.deep,
                        })
                        .then(res => {
                            if (res.data.success) {
                                console.log(res.data)
                            }

                            const reducer = (previousValue, currentValue) => previousValue + currentValue

                            if (res.data.point.reduce(reducer) > 0) {
                                this.show_results = true
                                this.max_proceeds = Math.max.apply(null, res.data.point)

                                let proceed_other = Math.round(res.data.all.reduce(reducer) / res.data.all_number_orders.reduce(reducer))
                                if (!proceed_other) proceed_other = 0

                                this.results_table = [
                                    {
                                        text: 'Выручка',
                                        value: new Intl.NumberFormat('ru-RU').format(Number(res.data.point.reduce(reducer))) + ' ₽',
                                    },
                                    {
                                        text: 'Заказы',
                                        value: res.data.point_number_orders.reduce(reducer) + ' шт',
                                    },
                                    {
                                        text: 'Средний чек',
                                        value: new Intl.NumberFormat('ru-RU').format(Number(Math.round(res.data.point.reduce(reducer) / res.data.point_number_orders.reduce(reducer)))) + ' ₽',
                                    },
                                    {
                                        text: 'Средний чек у других',
                                        value: new Intl.NumberFormat('ru-RU').format(proceed_other) + ' ₽',
                                    },
                                    {
                                        text: 'Объём рынка',
                                        value: new Intl.NumberFormat('ru-RU').format(Number(res.data.all.reduce(reducer) + res.data.point.reduce(reducer))) + ' ₽',
                                    },
                                ]

                                this.PiechartData = {
                                    labels: ['Выручка', 'Выручка у других'],
                                    datasets: [
                                        {
                                            label: '',
                                            data: [res.data.point.reduce(reducer), res.data.all.reduce(reducer)],
                                            backgroundColor: ['#4caf50', '#dc3546'],
                                            hoverOffset: 4,
                                            count_orders: [res.data.point_number_orders.reduce(reducer), res.data.all_number_orders.reduce(reducer)],
                                        },
                                    ],
                                }
                            } else {
                                this.show_results = false
                            }

                            let labels_bar = []
                            let beautiful_hour_labels = hour => {
                                let hour_str = ('0' + `${hour}`).slice(-2)
                                return hour_str + ':00'
                            }

                            res.data.date_arr.forEach(d => {
                                if ('hour' in d) {
                                    if (d.hour == 0) {
                                        labels_bar.push(`${d.date} ${d.month_of_year}, ${d.day_of_week}`)
                                    } else {
                                        labels_bar.push(`${beautiful_hour_labels(d.hour)}`)
                                    }
                                } else if ('date' in d) {
                                    if (d.year == this.time_now.getFullYear()) {
                                        labels_bar.push(`${d.date} ${d.month_of_year}, ${d.day_of_week}`)
                                    } else {
                                        labels_bar.push(`${d.date} ${d.month_of_year} ${String(d.year)}, ${d.day_of_week}`)
                                    }
                                } else {
                                    if (d.year == this.time_now.getFullYear()) {
                                        labels_bar.push(`${d.month_of_year}`)
                                    } else {
                                        labels_bar.push(`${d.month_of_year} ${d.year}`)
                                    }
                                }
                            })

                            this.chartData = {
                                labels: labels_bar || res.data.labels,
                                datasets: [
                                    {
                                        label: this.name_chart_point,
                                        backgroundColor: '#4caf50',
                                        data: res.data.point,
                                        number_orders: res.data.point_number_orders,
                                        date_object: res.data.date_arr,
                                    },
                                    {
                                        label: this.name_chart_all,
                                        backgroundColor: '#dc3546',
                                        data: res.data.all,
                                        number_orders: res.data.all_number_orders,
                                        date_object: res.data.date_arr,
                                    },
                                ],
                            }

                            this.bar_loading_show = false
                        })
                }
            },
        },
    }
</script>

<style lang="stylus" scoped></style>
