<template>
    <div class="analitics">
        <v-row style="max-width: 1200px" class="mx-auto">
            <v-col cols="12" :sm="interfaceCardSm" :md="interfaceCardMd">
                <v-card class="info-card mx-auto elevation-6" max-width="240" color="#2196F3" dark @click="goToLink('orders', true)">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <div class="overline">Заказы</div>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-list-item-title class="tooltip-text headline mb-1 align-center" v-bind="attrs" v-on="on"> <v-icon size="large" class="mr-1">mdi-inbox-multiple</v-icon> {{ ordersCountToday }} </v-list-item-title>
                                </template>
                                <span>Все заказы</span>
                            </v-tooltip>
                            <v-list-item-subtitle>за сегодня</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar size="60" class="mb-1">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <span class="tooltip-text" v-bind="attrs" v-on="on"> <v-icon size="large">mdi-check-bold</v-icon> {{ ordersCountComplete }} </span>
                                </template>
                                <span>Доставленные заказы</span>
                            </v-tooltip>
                        </v-list-item-avatar>
                    </v-list-item>
                </v-card>
            </v-col>

            <v-col cols="12" :sm="interfaceCardSm" :md="interfaceCardMd" v-if="interfaceCardOperator">
                <v-card class="info-card mx-auto elevation-6" max-width="240" color="#DC3546" dark @click="goToLink('points', false)">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <div class="overline">Точки</div>
                            <v-list-item-title class="tooltip-text headline mb-1 d-flex align-center">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div v-bind="attrs" v-on="on"><v-icon size="large">mdi-home-group</v-icon> {{ points.length }}</div>
                                    </template>
                                    <span>Всего точек</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div v-bind="attrs" v-on="on"><v-icon class="mr-1 ml-3" size="large">mdi-account-multiple</v-icon> {{ point_users.length }}</div>
                                    </template>
                                    <span>Всего менеджеров онлайн</span>
                                </v-tooltip>
                            </v-list-item-title>
                            <v-list-item-subtitle>сейчас</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar size="60" class="mb-1">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <span class="tooltip-text" v-bind="attrs" v-on="on"> <v-icon size="large">mdi-check-bold</v-icon> {{ pointsTodayCount }} </span>
                                </template>
                                <span>Точек в работе</span>
                            </v-tooltip>
                        </v-list-item-avatar>
                    </v-list-item>
                </v-card>
            </v-col>

            <v-col cols="12" :sm="interfaceCardSm" :md="interfaceCardMd">
                <v-card class="info-card mx-auto elevation-6" max-width="240" color="#4CAF50" dark @click="goToLink('couriers', false)">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <div class="overline">Курьеры</div>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-list-item-title class="tooltip-text headline mb-1 d-flex align-center" v-bind="attrs" v-on="on"> <v-icon size="large" class="mr-1">mdi-car-multiple</v-icon> {{ couriers.length }} </v-list-item-title>
                                </template>
                                <span>Всего курьеров онлайн</span>
                            </v-tooltip>
                            <v-list-item-subtitle>сейчас</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar size="60" class="mb-1">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <span class="tooltip-text" v-bind="attrs" v-on="on"> <v-icon size="large">mdi-check-bold</v-icon> {{ couriersTodayCount }} </span>
                                </template>
                                <span>{{ userIsPoint ? 'Курьеров на точке' : 'Курьеров в работе' }}</span>
                            </v-tooltip>
                        </v-list-item-avatar>
                    </v-list-item>
                </v-card>
            </v-col>

            <v-col cols="12" :sm="interfaceCardSm" :md="interfaceCardMd">
                <v-card class="info-card mx-auto elevation-6" max-width="240" color="#FF9800" dark @click="goToLink('couriers', false)">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <div class="overline">{{ interfaceFeeValue }}</div>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-list-item-title class="tooltip-text headline mb-1 d-flex align-center" v-bind="attrs" v-on="on"> <v-icon size="large" class="mr-1">mdi-cash-multiple</v-icon> {{ profitToday }} ₽ </v-list-item-title>
                                </template>
                                <span>{{ interfaceFeeValue }} всего за сегодня</span>
                            </v-tooltip>
                            <v-list-item-subtitle>за сегодня</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar size="60" class="mb-1">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <span class="tooltip-text" v-bind="attrs" v-on="on"> {{ profitDiff }} ₽ </span>
                                </template>
                                <span>{{ interfaceFeeValue }} по сравнению с предыдущим днем</span>
                            </v-tooltip>
                        </v-list-item-avatar>
                    </v-list-item>
                </v-card>
            </v-col>
            <v-col cols="12" :sm="interfaceCardSm" :md="interfaceCardMd">
                <v-card class="info-card mx-auto elevation-6" max-width="240" color="indigo darken-6" dark @click="goToLink('couriers', false)">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <div class="overline">Цена доставки</div>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-list-item-title class="tooltip-text headline mb-1 d-flex align-center" v-bind="attrs" v-on="on"> <v-icon size="large" class="mr-1">mdi-truck-fast</v-icon> {{ interfaceAveragePriceDeliveryMy || 0 }} ₽</v-list-item-title>
                                </template>
                                <span>Средняя цена доставки</span>
                            </v-tooltip>
                            <v-list-item-subtitle v-show="$store.state.user.role != 1">весь сервис: {{ interfaceAveragePriceDeliveryAll || 0 }} ₽</v-list-item-subtitle>
                            <v-list-item-subtitle v-show="$store.state.user.role == 1"><wbr></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-avatar size="60" class="mb-1">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <span class="tooltip-text" v-bind="attrs" v-on="on"> <v-icon size="large">mdi-check-bold</v-icon> {{ interfaceAveragePriceDeliveryMyCount || 0 }} </span>
                                </template>
                                <span>Доставленные заказы, на основе которых определена средняя цена, шт.</span>
                            </v-tooltip>
                        </v-list-item-avatar>
                    </v-list-item>
                </v-card>
            </v-col>
        </v-row>

        <v-row v-if="loaded" style="max-width: 1200px" class="mx-auto">
            <v-col cols="12" sm="12" :md="interfaceOverviewMd" :lg="interfaceOverviewLg">
                <v-card align="center" justify="center" class="overview mx-auto elevation-6">
                    <v-toolbar class="mb-2" height="50" color="cyan darken-1 elevation-3" dark flat>
                        <v-toolbar-title class="overline">Обзор заказов по статусам</v-toolbar-title>
                    </v-toolbar>
                    <Donut class="order-chart" :centerLabel="{ text: getOrdersCountPercent(), title: 'Процент доставленных заказов к общему количеству' }" :chartData="chartDataOrders" :colors="orderStatusColorsHtml()" />
                </v-card>
            </v-col>
            <v-col cols="12" sm="12" :md="interfaceOverviewMd" :lg="interfaceOverviewLg" v-if="interfaceOverviewOperator">
                <v-card align="center" justify="center" class="overview mx-auto elevation-6">
                    <v-toolbar class="mb-2" height="50" color="cyan darken-1 elevation-3" dark flat>
                        <v-toolbar-title class="overline">Обзор заказов по точкам, top-20</v-toolbar-title>
                    </v-toolbar>
                    <Donut class="order-chart" :chartData="chartDataPoints" :colors="colorsHtml" />
                </v-card>
            </v-col>
            <v-col cols="12" sm="12" :md="interfaceOverviewMd" :lg="interfaceOverviewLg">
                <v-card align="center" justify="center" class="overview mx-auto elevation-6">
                    <v-toolbar class="mb-2" height="50" color="cyan darken-1 elevation-3" dark flat>
                        <v-toolbar-title class="overline">Обзор заказов по курьерам, top-20</v-toolbar-title>
                    </v-toolbar>
                    <Donut class="order-chart" :chartData="chartDataCouriers" :colors="colorsHtml" />
                </v-card>
            </v-col>
        </v-row>

        <v-row style="max-width: 1800px" class="mx-auto">
            <v-col cols="12">
                <v-card class="mx-auto pb-3 elevation-6">
                    <v-toolbar class="mb-2" height="50" color="indigo darken-6 elevation-3" dark flat>
                        <v-toolbar-title class="overline">Последние 30 заказов</v-toolbar-title>
                    </v-toolbar>
                    <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">Сист.</th>
                                    <th class="text-left">Название</th>
                                    <th class="text-left">Статус</th>
                                    <th class="text-left">Гость</th>
                                    <th class="text-left">Адрес доставки</th>
                                    <th v-if="userIsPoint" class="text-left">Цена</th>
                                    <th v-if="!userIsPoint" class="text-left">Цена курьера</th>
                                    <th v-if="!userIsPoint" class="text-left">Цена точки</th>
                                    <th class="text-left">Курьер</th>
                                    <th class="text-left">Готов</th>
                                    <th class="text-left">Забрать</th>
                                    <th class="text-left">Доставить</th>
                                    <th class="text-left">Забран</th>
                                    <th class="text-left">Доставлен</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="item in orders.slice(0, 30)" :key="item.id">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <td class="tooltip-text" v-bind="attrs" v-on="on">
                                                <v-avatar size="24">
                                                    <img alt="source" :src="iconSource(item)" />
                                                </v-avatar>
                                            </td>
                                        </template>
                                        <span>{{ tooltipSource(item) }}</span>
                                    </v-tooltip>

                                    <tdFade bold :data="item.name" />

                                    <td>
                                        <v-chip :color="orderStatusColorHtml(item.status)" text-color="white">
                                            <v-icon size="small" class="mr-1">{{ orderStatusIcon(item.status) }}</v-icon>
                                            {{ orderStatusStr(item.status) }}
                                        </v-chip>
                                    </td>

                                    <!-- <template v-slot:[`item.guest`]="{ item }">
                                        <span style="min-width: 170px">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <td v-bind="attrs" v-on="on">
                                                        
                                                        <router-link v-if="item.guest_phone" :to="{ name: 'guests_phone', params: {phone: item.guest_phone} }" class="text-decoration-none" style="color:inherit">
                                                            <span class="py-2 tooltip-text" :class="loading || item.status == config.order.status.forming ? '' : item.guest_phone ? 'guest-selectable' : 'guest-error'" @click="onClickGuest(item)">{{ getGuestPhoneName(item) }}</span>
                                                        </router-link>

                                                        <span v-else class="py-2 tooltip-text" :class="loading || item.status == config.order.status.forming ? '' : item.guest_phone ? 'guest-selectable' : 'guest-error'" @click="onClickGuest(item)">{{ getGuestPhoneName(item) }}</span>

                                                    </td>
                                                </template>
                                                <span>{{ getGuestStr(item.guest) }}</span>
                                            </v-tooltip>
                                            <v-btn class="ml-2" v-if="!item.guest_id && item.status != config.order.status.forming" outlined tile text x-small @click="onClickGuestCreate(item)"><v-icon small>mdi-account-plus-outline</v-icon></v-btn>
                                        </span>
                                    </template> -->

                                    <!-- <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <td class="tooltip-text" v-bind="attrs" v-on="on"> -->

                                    <!-- <router-link v-if="item.guest_phone" :to="{ name: 'guests_phone', params: {phone: item.guest_phone} }" class="text-decoration-none" style="color:inherit">

                                                    <span class="py-2 tooltip-text" :class="item.status == config.order.status.forming ? '' : item.guest_phone ? 'guest-selectable' : 'guest-error'" @click="onClickGuest(item)">{{ getGuestPhoneName(item) }}</span>

                                                </router-link>


                                                <span v-else class="py-2 tooltip-text" :class="item.status == config.order.status.forming ? '' : item.guest_phone ? 'guest-selectable' : 'guest-error'" @click="onClickGuest(item)">{{ getGuestPhoneName(item) }}</span> -->

                                    <!-- <tdFade v-if="item.guest_phone" class="py-2 tooltip-text guest-selectable" @click="onClickGuest(item)" :data="getGuestPhoneName(item)" /> -->

                                    <!-- <router-link v-if="item.guest_phone" :to="{ name: 'guests_phone', params: {phone: item.guest_phone} }" class="text-decoration-none" style="color:inherit">
                                                    <span class="py-2 tooltip-text" :class="loading || item.status == config.order.status.forming ? '' : item.guest_phone ? 'guest-selectable' : 'guest-error'" @click="onClickGuest(item)">{{ getGuestPhoneName(item) }}</span>
                                                </router-link> -->

                                    <!-- <span v-else class="py-2 tooltip-text guest-error" :class="item.status == config.order.status.forming ? '' : item.guest_phone ? 'guest-selectable' : 'guest-error'">{{ getGuestPhoneName(item) }}</span> -->

                                    <!-- </td>
                                        </template>
                                        <span>{{ getGuestStr(item.guest) }}</span>
                                    </v-tooltip> -->

                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <td class="tooltip-text" v-bind="attrs" v-on="on">
                                                <tdFade :data="getGuestStr(item.guest).substr(0, 24)" />
                                            </td>
                                        </template>
                                        <span>{{ getGuestStr(item.guest) }}</span>
                                    </v-tooltip>

                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <td class="tooltip-text" v-bind="attrs" v-on="on" :style="item.fee_courier == 0 || item.fee_courier == $store.state.settings.price_courier_default ? 'color: red' : ''">
                                                <tdFade :data="item.finish_addr.substr(0, 24)" />
                                            </td>
                                        </template>
                                        <span>{{ item.finish_addr }}</span>
                                    </v-tooltip>

                                    <td v-if="userIsPoint" :style="item.fee_courier == 0 || item.fee_courier == $store.state.settings.price_courier_default ? 'color: red' : ''">
                                        <tdFade :data="+item.fee_point" />
                                    </td>

                                    <td v-if="!userIsPoint" :style="item.fee_courier == 0 || item.fee_courier == $store.state.settings.price_courier_default ? 'color: red' : ''">
                                        <tdFade :data="+item.fee_courier" />
                                    </td>

                                    <td v-if="!userIsPoint" :style="item.fee_courier == 0 || item.fee_courier == $store.state.settings.price_courier_default ? 'color: red' : ''">
                                        <tdFade :data="+item.fee_point" />
                                    </td>

                                    <td class="d-flex align-center">
                                        <v-avatar size="24" class="mr-2" v-if="item.courier_name != '--'">
                                            <img alt="courier" :src="`${config.api.delivery + 'user_avatar?tid=' + item.courier_tid}`" />
                                        </v-avatar>
                                        <tdFade bold :data="item.courier_name" />
                                    </td>

                                    <tdFade :data="item.mode.split(' ')[0] + ' ' + timeToString(item.date)" />

                                    <tdFade :data="timeToString(item.date_ready)" />

                                    <tdFade :data="timeToString(item.date_delivery)" />

                                    <td>
                                        <span class="mr-2">{{ timeToString(item.date_take) }}</span>
                                        <v-chip v-if="item.date_take" class="px-1" :color="timeColorHtml(item.date_take, item.date_ready)" x-small label text-color="white">
                                            {{ timeError(item.date_take, item.date_ready) }}
                                        </v-chip>
                                    </td>

                                    <td>
                                        <span class="mr-2">{{ timeToString(item.date_complete) }}</span>
                                        <v-chip v-if="item.date_complete" class="px-1" :color="timeColorHtml(item.date_complete, item.date_delivery)" x-small label text-color="white">
                                            {{ timeError(item.date_complete, item.date_delivery) }}
                                        </v-chip>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <span v-if="!orders.length" class="no-data"> Нет данных </span>
                </v-card>
            </v-col>
        </v-row>
        <Proceeds />
        <span class="pb-16 d-block" />
    </div>
</template>

<script>
    import config from '../../config'
    import Donut from './Donut.vue'
    import TdFade from './TdFade.vue'
    import Proceeds from './Proceeds.vue'

    import DateTime from '../../mixins/DateTime.js'
    import Status from '../../mixins/Status.js'

    export default {
        mixins: [DateTime, Status],
        data() {
            return {
                config,
                loaded: false,
                chartDataOrders: {},
                chartDataPoints: {},
                chartDataCouriers: {},
                ordersCountToday: '--',
                ordersCountComplete: '--',
                pointsTodayCount: '--',
                couriersTodayCount: '--',
                orders: [],
                points: [],
                couriers: [],
                point_users: [],
                users: [],
                pointsToday: [],
                pointsTodayCounts: [],
                couriersToday: [],
                couriersTodayCounts: [],
                couriersSelected: [],
                labelsSelected: [],
                profitToday: 0,
                profitYesterday: 0,
                profitDiff: 0,
                interfaceFeeValue: true,
                interfaceCardOperator: true,
                interfaceCardMd: 3,
                interfaceCardSm: 6,
                interfaceOverviewOperator: true,
                interfaceOverviewMd: 6,
                interfaceOverviewLg: 4,
                interfaceAveragePriceDeliveryMy: 0,
                interfaceAveragePriceDeliveryMyCount:0,
                interfaceAveragePriceDeliveryAll: 0,

                orderStatuses: [],
                timer: null,
                userIsPoint: false,

                loading: false,
            }
        },
        components: {
            Donut,
            TdFade,
            Proceeds,
        },
        mounted() {
            console.log('!!! mounted !!!')
            // console.log('NOW - ', moment().format('YYYY-MM-DD'))

            this.userIsPoint = this.$store.state.user.role != config.user.role.operator

            if (this.$store.state.user.role == config.user.role.operator) {
                // this.interfaceFeeValue = 'Выручка'
                this.interfaceFeeValue = 'Зарплата'

                this.interfaceCardOperator = true
                this.interfaceCardMd = 4
                this.interfaceCardSm = 6

                this.interfaceOverviewOperator = true
                this.interfaceOverviewMd = 6
                this.interfaceOverviewLg = 4
            } else {
                this.interfaceFeeValue = 'Расходы'
                // this.interfaceFeeValue = 'Зарплата'

                this.interfaceCardOperator = false
                this.interfaceCardMd = 6
                this.interfaceCardSm = 6

                this.interfaceOverviewOperator = false
                this.interfaceOverviewMd = 12
                this.interfaceOverviewLg = 6
            }

            this.updateData()
            this.timer = setInterval(() => {
                this.updateData()
            }, 5000)
            this.getAveragePriceDelivery()
        },

        beforeDestroy() {
            console.log('!!! beforeDestroy !!!')
            if (this.chart) {
                this.chart.dispose()
            }
        },

        destroyed() {
            console.log('!!! destroyed !!!')
            return this.timer ? clearInterval(this.timer) : false
        },
        watch: {},
        computed: {},
        methods: {
            getAveragePriceDelivery(){
                let how = ""
                let id = 0
                this.role = this.$store.state.user.role

                switch (this.role) {
                    case 1: //operator
                        how = "operator"
                        id = this.$store.state.user.tid || 0
                        break
                    case 3: //point
                        how = "point"
                        id = this.$store.state.user.point_id || 0
                        break
                    default:
                        break
                }

                this.$store
                        .dispatch('get_average_price_delivery', {
                            how: how, //
                            id: id,
                        })
                        .then(res => {
                            if (res.data) {
                                this.interfaceAveragePriceDeliveryMy = res.data.my
                                this.interfaceAveragePriceDeliveryMyCount = res.data.my_count
                                this.interfaceAveragePriceDeliveryAll = res.data.all
                            }
                            })
            },
            onClickGuest(order) {
                if (order.guest_id) {
                    const name = order.guest_name ? order.guest_name + ' ' : ''
                    this.$notify.toast('Гость: ' + name + order.guest_phone, { color: 'info', timeout: 4000 })
                    this.$router.push({ name: 'guests_phone', params: { phone: order.guest_phone } })
                    // this.$router.push({ name: 'guests_phone', params: {phone: order.guest_phone} })
                }
            },
            goToLink(name, all_users) {
                if (!this.userIsPoint || all_users) {
                    this.$router.push({ name })
                }
            },
            getOrdersCountPercent() {
                return this.ordersCountToday > 0 ? Math.round((this.ordersCountComplete / this.ordersCountToday) * 100) + '%' : ''
            },
            getGuestStr(guest) {
                let splittedStr = guest.split('Телефон:')
                return splittedStr.length > 1 ? splittedStr[0] + ' ' + splittedStr[1].substr(0, 15) : guest
            },
            getGuestPhoneName(order) {
                let guest_name = ''
                if (order.guest_id && order.guest_phone) {
                    guest_name += order.guest_phone
                    if (order.guest_name) guest_name += ' - ' + order.guest_name
                } else {
                    if (order.guest) {
                        guest_name = this.getGuestStr(order.guest).substr(0, 24)
                    }
                }
                return guest_name
            },
            getTdCatted(str) {
                return '<td>Hello</td>'
            },
            pointNameGet: function (point_id) {
                let point_name = '--'
                const point = this.points.find(p => p.id == point_id)
                if (point) {
                    point_name = point.name
                }
                return point_name
            },
            courierNameGet: function (courier_tid) {
                let courier_name = '--'
                const courier = this.users.find(user => user.tid == courier_tid)
                if (courier) {
                    courier_name = courier.full_name
                }
                return courier_name
            },
            fillDataOrders() {
                this.chartDataOrders = {
                    labels: this.orderStatusLabels(),
                    disabled: [],
                    datasets: [
                        {
                            backgroundColor: this.orderStatusColorsHtml(),
                            data: this.orderStatuses,
                        },
                    ],
                }
                console.log('this.chartDataOrders', this.chartDataOrders)
            },
            fillDataPoints() {
                this.chartDataPoints = {
                    labels: this.pointsLabels(),
                    disabled: [],
                    datasets: [
                        {
                            backgroundColor: this.colorsHtml,
                            data: this.pointsTodayCounts,
                        },
                    ],
                }
                console.log('this.chartDataPoints', this.chartDataPoints)
            },
            fillDataCouriers() {
                const labels = this.couriersLabels()
                this.chartDataCouriers = {
                    labels: labels[0],
                    disabled: labels[1],
                    datasets: [
                        {
                            backgroundColor: this.colorsHtml,
                            data: this.couriersTodayCounts,
                        },
                    ],
                }
            },
            pointsLabels() {
                const res = []
                this.pointsToday.forEach((point_id, i) => {
                    res[i] = this.pointNameGet(point_id).substr(0, 20)
                })
                return res
            },
            couriersLabels() {
                const res = [[], []]
                this.couriersToday.forEach((courier_tid, i) => {
                    res[0][i] = this.courierNameGet(courier_tid).substr(0, 20)
                    res[1][i] = this.couriers.findIndex(courier => courier.tid == courier_tid) == -1
                })
                return res
            },
            orderStatusLabels() {
                const res = [[], []]
                for (let i = 0; i < 6; i++) {
                    res[i] = this.orderStatusStr(i)
                }
                return res
            },
            orderStatusColorsHtml() {
                return [this.orderStatusColorHtml(0), this.orderStatusColorHtml(1), this.orderStatusColorHtml(2), this.orderStatusColorHtml(3), this.orderStatusColorHtml(4), this.orderStatusColorHtml(5)]
            },
            updateData() {
                let point_id = 0
                if (this.userIsPoint) {
                    point_id = this.$store.state.user.point_id
                }

                this.$store
                    .dispatch('orders_date', {
                        date1: this.getDate1String(),
                        date2: this.getDate2String(),
                        point_id,
                        deliveryTime: true,
                    })
                    .then(res => {
                        if (res.data.success) {
                            this.orders = res.data.orders.reverse()

                            console.log('this.orders', this.orders)
                            const ordersComplete = this.orders.map(order => order.state == config.order.status.complete)
                            this.ordersCountToday = ordersComplete.length
                            this.orderStatuses[config.order.status.forming] = 0
                            this.orderStatuses[config.order.status.new] = 0
                            this.orderStatuses[config.order.status.passed] = 0
                            this.orderStatuses[config.order.status.way_point] = 0
                            this.orderStatuses[config.order.status.way_guest] = 0
                            this.orderStatuses[config.order.status.complete] = 0
                            const pointsTodaySet = new Set()
                            const couriersTodaySet = new Set()
                            this.orders.forEach(order => {
                                if (order.point_id != null) {
                                    pointsTodaySet.add(order.point_id)
                                }
                                if (order.courier_tid != null) {
                                    couriersTodaySet.add(order.courier_tid)
                                }
                            })
                            this.pointsTodayCount = pointsTodaySet.size
                            this.couriersTodayCount = couriersTodaySet.size
                            this.pointsToday = Array.from(pointsTodaySet)
                            this.couriersToday = Array.from(couriersTodaySet)
                            this.pointsToday.forEach((p_id, i) => {
                                this.pointsTodayCounts[i] = 0
                            })
                            this.couriersToday.forEach((c_tid, i) => {
                                this.couriersTodayCounts[i] = 0
                            })

                            // let i = 0
                            this.orders.forEach(order => {
                                this.orderStatuses[order.status]++
                                this.pointsToday.forEach((p_id, i) => {
                                    if (p_id == order.point_id) {
                                        this.pointsTodayCounts[i]++
                                    }
                                })
                                this.couriersToday.forEach((c_tid, i) => {
                                    if (c_tid == order.courier_tid) {
                                        this.couriersTodayCounts[i]++
                                    }
                                })
                            })
                            let mapped = this.pointsTodayCounts.map((el, i) => {
                                return { index: i, value: el }
                            })
                            mapped.sort((a, b) => b.value - a.value)
                            mapped = mapped.slice(0, 20)
                            this.pointsToday = mapped.map(el => this.pointsToday[el.index])
                            this.pointsTodayCounts = mapped.map(el => this.pointsTodayCounts[el.index])

                            mapped = this.couriersTodayCounts.map((el, i) => {
                                return { index: i, value: el }
                            })
                            mapped.sort((a, b) => b.value - a.value)
                            mapped = mapped.slice(0, 20)
                            this.couriersToday = mapped.map(el => this.couriersToday[el.index])
                            this.couriersTodayCounts = mapped.map(el => this.couriersTodayCounts[el.index])

                            this.ordersCountComplete = this.orderStatuses[config.order.status.complete]

                            // let i
                            // for (const o of this.orderStatuses[config.order.status.formed]) {
                            //     console.log(i + 1, o.name, o.id)
                            // }

                            this.profitToday = 0
                            for (var order of this.orders) {
                                if (order.date_complete) {
                                    if (!order.fee_point) order.fee_point = 0
                                    if (!order.fee_courier) order.fee_courier = 0
                                    this.profitToday += parseInt(this.userIsPoint ? order.fee_point : order.fee_courier)
                                }
                            }

                            // this.$store
                            //     .dispatch('guests_get', {
                            //         date1: this.getDate1String(),
                            //         date2: this.getDate2String(),
                            //         point_id: 0,
                            //     })
                            //     .then(res => {
                            //         if (res.data.success) {
                            //             const guests = res.data.guests
                            //             // console.log('guests', guests)
                            //             for (let order of this.orders) {
                            //                 if (order.guest_id) {
                            //                     const guest = guests.find(g => g.id == order.guest_id)
                            //                     if (guest) {
                            //                         order.guest_name = guest.name
                            //                         order.guest_phone = guest.phone
                            //                     }
                            //                 }
                            //             }
                            //             console.log('guests_get this.orders', this.orders)
                            //         }

                            //     })
                            //     .catch(err => {
                            //         console.log('2 error', err.name, err.message)
                            //     })

                            this.$store
                                .dispatch('orders_date', {
                                    date1: this.getDate1String(-1),
                                    date2: this.getDate2String(-1),
                                    point_id,
                                    deliveryTime: true,
                                })
                                .then(res => {
                                    if (res.data.success) {
                                        const orders_yesterday = res.data.orders
                                        console.log('orders_yesterday', orders_yesterday)
                                        this.profitYesterday = 0
                                        for (var order of orders_yesterday) {
                                            if (order.date_complete) {
                                                if (!order.fee_point) order.fee_point = 0
                                                if (!order.fee_courier) order.fee_courier = 0
                                                this.profitYesterday += parseInt(this.userIsPoint ? order.fee_point : order.fee_courier)
                                            }
                                        }
                                        this.profitDiff = this.profitToday - this.profitYesterday
                                        if (this.profitDiff > 0) {
                                            this.profitDiff = '+' + this.profitDiff
                                        }
                                    }
                                })
                        } else {
                            this.ordersCountToday = 0
                            this.ordersCountComplete = 0
                        }
                        this.loaded = true
                        this.fillDataOrders()
                        if (!this.userIsPoint) {
                            this.fillDataPoints()
                        }
                        this.fillDataCouriers()
                    })
                    .catch(err => {
                        console.log(err)
                    })

                if (!this.userIsPoint) {
                    this.$store
                        .dispatch('points_get')
                        .then(res => {
                            if (res.data.success) {
                                this.points = res.data.points
                                console.log('points', this.points)
                            }
                            this.fillDataPoints()
                        })
                        .catch(err => {
                            console.log(err)
                        })
                }

                this.$store
                    .dispatch('users_get')
                    .then(res => {
                        if (res.data.success) {
                            this.users = res.data.users
                            this.couriers = this.users.filter(u => u.enable && u.role == config.user.role.courier)
                            this.point_users = this.users.filter(u => u.enable && u.point_id && u.role == config.user.role.point)
                            console.log('users', this.users)
                            console.log('couriers', this.couriers)
                            console.log('point_users', this.point_users)
                        }
                        this.fillDataCouriers()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
        },
    }
</script>

<style lang="stylus" scoped>
    .analitics
        height 100%
        padding-top 10px !important
        .overview
            max-width 400px
        .text
            padding 0
        .v-card
            cursor default
        .v-toolbar
            border-bottom-left-radius 0 !important
            border-bottom-right-radius 0 !important

        .v-data-table
            padding 0 16px
            .v-data-table__wrapper > table > thead > tr
                th
                    white-space nowrap
                    padding 0 8px

            .v-data-table__wrapper > table > tbody > tr
                &:hover
                    background-color transparent !important
                td
                    white-space nowrap
                    cursor default
                    .text
                        padding 0 8px

                    padding 0 8px
                    font-size 0.75rem
                    p
                        margin-top 6px
                        margin-bottom 6px
                        height 22px
                    td.tooltip-text
                        padding-left 0
                    td
                        padding-left 0

        .v-tooltip__content
            background #222
            color #fff
            border-radius 6px
            padding 5px 10px 4px

        .v-chip
            &.v-size--default
                font-size 0.75rem
                height 20px
                border-radius 16px
                margin 4px
                cursor default
                padding 0 6px

        .v-chip.v-size--x-small
            height 18px

        b
            font-weight 500

        .info-card
            cursor pointer

        .v-list-item__content
            padding 8px 0
            overflow initial

        .no-data
            width 100%
            display block
            text-align center
            color #999
            font-size 13px
            height 30px
            line-height 30px

        .guest-error
            color #F44336

        .guest-selectable
            font-weight 400
            color #0D47A1
            cursor pointer
            &:hover
                text-decoration: underline
</style>
